import {getAccessToken, usePrivy} from '@privy-io/react-auth';
import {getCampaign, getUserId, getResponses} from '@unegma/sa2-lib-frontend';
import {useEffect, useState} from 'react';
import {Tabs, Tab, Button} from '@mui/material';
import CustomTabPanel from './CustomTabPanel';
import DetailsTab from './DetailsTab';
import PasswordTab from './PasswordTab';
import ResponsesTab from './ResponsesTab';
import BrandingTab from './BrandingTab';

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

export function AccountPage() {
  const {user} = usePrivy();
  console.log(getUserId(user));
  const [responses, setResponses] = useState<[]>([]);
  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getAccessToken().then((token: any) => {
      getResponses(ENDPOINT, token).then((response: any) => {
        // todo make this type 'GetSA2Response' OR 'error'
        console.log('responses', response);
        if (response !== 'error' && Object.keys(response).length !== 0) {
          setResponses(response);
        }
      });
    });
  }, []);

  return (
    <div className="account-page pt-[100px] px-[40px]">
      <h1 className="text-[40px] pb-4">Account Settings</h1>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        {/*<Tab label="Your Details" className="tab" />*/}
        {/*<Tab label="Password" className="tab" />*/}
        <Tab label="Branding" className="tab" />
        <Tab label="Responses" className="tab" />
      </Tabs>
      {/*<CustomTabPanel value={value} index={0}>*/}
      {/*  <DetailsTab />*/}
      {/*</CustomTabPanel>*/}
      {/*<CustomTabPanel value={value} index={1}>*/}
      {/*  <PasswordTab />*/}
      {/*</CustomTabPanel>*/}
      <CustomTabPanel value={value} index={0}>
        <BrandingTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ResponsesTab responses={responses} />
      </CustomTabPanel>
    </div>
  );
}
