import React from 'react';
import AudioPlayer from '../../../shared-components/AudioPlayer/AudioPlayer';

type Props = {
  responses: any[];
};

function ResponsesTab({responses}: Props) {
  return (
    <div className="w-1/2 px-5">
      <h2 className="pt-4 pb-4">My Responses ({responses.length})</h2>
      <div>
        {responses.map((response: any) => {
          return (
            // todo might want to scrub the uuid?
            <div key={response.uuid}>
              <AudioPlayer
                recording={`https://sa2-${process.env.REACT_APP_APP_ENV}-responses.s3.eu-west-2.amazonaws.com/${response.filename}`}
              />
              <p className="text-sm pt-4 pl-4">
                Transcription: {response.transcription}
              </p>
              <p className="text-sm pb-6 pl-4">
                Sentiment: {response.sentiment.Sentiment}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ResponsesTab;
