import React from 'react';

type Props = {
  emotionWeightings: {
    angry: string;
    calm: string;
    disgust: string;
    fearful: string;
    happy: string;
    neutral: string;
    sad: string;
    surprised: string;
  };
};

function EmotionBar({emotionWeightings}: Props) {
  return (
    <div>
      <div className="flex h-4 md:h-7 w-full rounded-full">
        <div
          style={{width: `${emotionWeightings.angry}`}}
          className="h-full rounded-s-full border-y-2 border-l-2
          bg-[#53FFFF] border-[#53FFFF] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.calm}`}}
          className="h-full border-2
          bg-[#D3D2F6] border-[#D3D2F6] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.disgust}`}}
          className="h-full border-2
          bg-[#FB8C5D] border-[#FB8C5D] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.fearful}`}}
          className="h-full rounded-e-full border-y-2 border-r-2
          bg-[#FD6779] border-[#FD6779] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.happy}`}}
          className="h-full rounded-e-full border-y-2 border-r-2
          bg-[#FD6779] border-[#FD6779] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.neutral}`}}
          className="h-full rounded-e-full border-y-2 border-r-2
          bg-[#FD6779] border-[#FD6779] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.sad}`}}
          className="h-full rounded-e-full border-y-2 border-r-2
          bg-[#FD6779] border-[#FD6779] bg-opacity-30"
        ></div>
        <div
          style={{width: `${emotionWeightings.surprised}`}}
          className="h-full rounded-e-full border-y-2 border-r-2
          bg-[#FD6779] border-[#FD6779] bg-opacity-30"
        ></div>
      </div>
    </div>
  );
}

export default EmotionBar;
