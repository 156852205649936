import {usePrivy} from '@privy-io/react-auth';
import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '@mui/material/Avatar';

/**
 * Logged in navbar
 * @constructor
 */

const options = ['My Account', 'Wallet', 'Log Out'];

export default function Navbar() {
  const {logout, user} = usePrivy();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavMenuClick = (option: string) => {
    switch (option) {
      case 'My Account':
        navigate('/account');
        break;
      case 'Wallet':
        navigate('/wallet');
        break;
      case 'Log Out':
        logout();
        break;
    }
  };

  console.log(`User: ${JSON.stringify(user)}`);

  const [showNavbar, setShowNavbar] = React.useState(true);
  const location = useLocation();
  console.log('pathname is: ', location.pathname);

  useEffect(() => {
    if (location.pathname.includes('/sa2-campaigns/')) {
      console.log('setting to false');
      setShowNavbar(false);
    } else {
      console.log('setting to true');
      setShowNavbar(true);
    }
  }, [location]);

  return (
    <>
      {showNavbar && (
        <div className="navbar">
          <RouterLink to={`/`} color="inherit" className="pt-1">
            {/*<img src={`${process.env.REACT_APP_BASE_URL}/sa2-logo.svg`} />*/}
            SA2
          </RouterLink>

          <div className="navbar__right-links">
            {/*<Avatar sx={{width: 32, height: 32, bgcolor: '#000'}}>A</Avatar>*/}

            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <KeyboardArrowDownIcon htmlColor="#898A8D" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {options.map(option => (
                <MenuItem
                  key={option}
                  onClick={() => handleNavMenuClick(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      )}
    </>
  );
}
