import {useState} from 'react';
import * as React from 'react';
import {Button, Input} from '@mui/material';
import {signUp} from '@unegma/sa2-lib-frontend';

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

/**
 * Login Screen
 * @constructor
 */
export default function LoginScreen() {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [game, setGame] = useState('');
  const [communitySize, setCommunitySize] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSignUp = async () => {
    const signUpData = {
      name,
      company,
      game,
      communitySize,
      email,
      phone,
    };

    console.log('sign up data', signUpData);

    if (!signUpData.name || !signUpData.company || !signUpData.email) {
      alert('Please fill in all required fields');
      return;
    } else {
      setSubmitting(true);

      const response = await signUp(ENDPOINT, null, signUpData);

      if (response !== 'error') {
        alert(`Sign Up Successful`);
      } else {
        alert('There was an error signing up');
      }
      setSubmitting(false);
    }
  };

  const handleChangeYourName = (event: any) => {
    setName(event.target.value);
  };
  const handleChangeCompany = (event: any) => {
    setCompany(event.target.value);
  };
  const handleChangeCompanyEmail = (event: any) => {
    setEmail(event.target.value);
  };
  const handleChangePhone = (event: any) => {
    setPhone(event.target.value);
  };

  return (
    <div className="login-screen">
      {/*<img className="login-screen__grid" src="grid-gradient.svg" />*/}

      <div className="login-screen__left-column">
        <img className="login-screen__laptop" src="laptop.png" />
      </div>

      {/*<div className="login-screen__right-column">*/}
      {/*  <div className="login-screen__sign-up">*/}
      {/*    <h1 className="login-screen__sign-up-title">*/}
      {/*      Apply for early access*/}
      {/*    </h1>*/}

      {/*    <span className="login-screen__sign-up-label">Name*</span>*/}
      {/*    <Input*/}
      {/*      required*/}
      {/*      className="login-screen__input"*/}
      {/*      style={{backgroundColor: 'white'}}*/}
      {/*      // placeholder="Type your question here"*/}
      {/*      onChange={(event: any) => {*/}
      {/*        handleChangeYourName(event);*/}
      {/*      }}*/}
      {/*    />*/}

      {/*    <span className="login-screen__sign-up-label">Company Name*</span>*/}
      {/*    <Input*/}
      {/*      required*/}
      {/*      className="login-screen__input"*/}
      {/*      style={{backgroundColor: 'white'}}*/}
      {/*      // placeholder="Type your question here"*/}
      {/*      onChange={(event: any) => {*/}
      {/*        handleChangeCompany(event);*/}
      {/*      }}*/}
      {/*    />*/}

      {/*    <span className="login-screen__sign-up-label">Company Email*</span>*/}
      {/*    <Input*/}
      {/*      required*/}
      {/*      className="login-screen__input"*/}
      {/*      style={{backgroundColor: 'white'}}*/}
      {/*      // placeholder="Type your question here"*/}
      {/*      onChange={(event: any) => {*/}
      {/*        handleChangeCompanyEmail(event);*/}
      {/*      }}*/}
      {/*    />*/}

      {/*    <span className="login-screen__sign-up-label">*/}
      {/*      Phone Number (optional)*/}
      {/*    </span>*/}
      {/*    <Input*/}
      {/*      required*/}
      {/*      className="login-screen__input"*/}
      {/*      style={{backgroundColor: 'white'}}*/}
      {/*      // placeholder="Type your question here"*/}
      {/*      onChange={(event: any) => {*/}
      {/*        handleChangePhone(event);*/}
      {/*      }}*/}
      {/*    />*/}

      {/*    <br />*/}

      {/*    <Button*/}
      {/*      className="sa2-button"*/}
      {/*      disabled={submitting}*/}
      {/*      variant="contained"*/}
      {/*      onClick={() => {*/}
      {/*        handleSignUp();*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Apply*/}
      {/*    </Button>*/}

      {/*    <br />*/}
      {/*    <br />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}
