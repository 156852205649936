import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import '../App.scss';
import Navbar from './Navbar';
import LoginScreen from './LoginScreen';
import ResponsesScreen from './ResponsesScreen';

/**
 * App when user is not logged in
 * @constructor
 */
export default function UnauthenticatedApp() {
  return (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<LoginScreen />} />
          {/*todo change path*/}
          <Route path="/sa2-campaigns/:campaignId" element={<ResponsesScreen />} />
          <Route path="*" element={<LoginScreen />} />

          {/* todo might need a fallback */}
        </Routes>
      </Router>
    </>
  );
}
