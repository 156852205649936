import React, {useState} from 'react';
import {createCampaign, getUserId, uploadLogo} from '@unegma/sa2-lib-frontend';
import {getAccessToken, usePrivy} from '@privy-io/react-auth';
import {Button} from '@mui/material';
import {ArrowUpTrayIcon} from '@heroicons/react/24/outline';
const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

export default function ImageUpload() {
  const [selectedImage, setSelectedImage] = useState<File>(null!);
  const [submitting, setSubmitting] = useState(false);

  const {user} = usePrivy();

  const handleAddImage = (event: any) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  };

  const setUpload = async () => {
    console.log('uploading');

    if (selectedImage.size > 1024 * 1024) {
      // 1MB
      alert('File is too large! Please upload a smaller file.');
      return;
    }

    setSubmitting(true);
    const token = await getAccessToken();

    const logoLink = await uploadLogo(
      ENDPOINT,
      token,
      getUserId(user),
      selectedImage,
    );
    console.log(logoLink);

    if (logoLink !== 'error') {
      // todo do error handling in frontend library
      alert(`Image uploaded! Your link: ${logoLink}`);
    } else {
      alert('There was an error uploading logo');
    }

    setSubmitting(false);
  };

  return (
    <div>
      {selectedImage && (
        <div className="flex flex-row items-center pb-4 gap-4">
          <img
            alt="not found"
            width={'250px'}
            src={URL.createObjectURL(selectedImage)}
            className="bg-black"
          />
          <Button
            disabled={submitting}
            variant="contained"
            onClick={() => setUpload()}
          >
            Save
          </Button>
          {/* <button onClick={() => setSelectedImage(null)}>Remove</button> */}
        </div>
      )}

      <label
        onChange={(event: any) => {
          handleAddImage(event);
        }}
        htmlFor="formId"
      >
        <input type="file" name="myImage" id="formId" hidden />
        <div className="cursor-pointer bg-[#4C46DC] w-[180px] px-3 py-2 rounded-full flex justify-center items-center gap-2">
          <ArrowUpTrayIcon className="w-6 h-6" />
          <p className="text-sm">Upload New Logo</p>
        </div>
      </label>
    </div>
  );
}
