import {getAccessToken, usePrivy} from "@privy-io/react-auth";
import {getUserId, getResponses} from "@unegma/sa2-lib-frontend";
import {useEffect, useState} from "react";

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

export function WalletPage () {

  const {user} = usePrivy();
  console.log(user)
  console.log(user?.wallet)

  const [sa2s, setSA2s] = useState<[]>([]);

  useEffect(() => {
    getAccessToken().then((token: any) => {
      getResponses(ENDPOINT, token).then(
        (response: any) => { // todo make this type 'GetSA2sResponse' OR 'error'
          console.log('sa2s', response);
          if (response !== 'error' && Object.keys(response).length !== 0) {
            setSA2s(response);
          }
        });
    });
  }, []);

  return (
    <>
      <div className="wallet">
        <br/>
        <br/>
        <br/>

        <p>Your Wallet address:</p>
        <p>{`${user?.wallet?.address}`}</p>

        <br/>

        <p>Your SA2Token balance:</p>
        <p>0</p>
        <p>Eligable SA2s Submitted: ({sa2s.length})</p>
      </div>
    </>
  )
}
