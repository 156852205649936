import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import CampaignScreen from './pages/CampaignScreen/CampaignScreen';
import '../App.scss';
import Navbar from './components/Navbar';
import HomeScreen from './pages/HomeScreen';
import CreateCampaignScreen from './pages/CreateCampaignScreen';
import ResponsesScreen from '../UnauthenticatedApp/ResponsesScreen';
import InsightScreen from './pages/InsightScreen';
import {AccountPage} from './pages/AccountPage/AccountPage';
import {WalletPage} from './pages/WalletPage';

export default function AuthenticatedApp() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeScreen />} />

          <Route path="/account" element={<AccountPage />} />
          <Route path="/wallet" element={<WalletPage />} />

          <Route path="/campaigns/:campaignId" element={<CampaignScreen />} />
          <Route
            path="/campaigns/:campaignId/insights/:insightId"
            element={<InsightScreen />}
          />
          <Route path="/create-campaign" element={<CreateCampaignScreen />} />
          <Route path="/sa2-campaigns/:campaignId" element={<ResponsesScreen />} />

          {/* todo fallback */}
          <Route path="*" element={<HomeScreen />} />
        </Routes>
      </Router>
    </>
  );
}
