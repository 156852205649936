import {usePrivy} from '@privy-io/react-auth';
import './App.scss';
import AuthenticatedApp from './AuthenticatedApp/AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp/UnauthenticatedApp';
import LoadingScreen from './shared-components/LoadingScreen';

function App() {
  const {ready, authenticated} = usePrivy();

  return (
    <>
      <div
        style={{
          zIndex: '99',
          color: 'red',
          width: '100%',
          position: 'fixed',
          textAlign: 'center',
        }}
        hidden={process.env.REACT_APP_APP_ENV === 'production'}
      >
        Staging
      </div>
      {!ready ? (
        <LoadingScreen />
      ) : authenticated ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}

      <p className="terms">Terms and Conditions</p>
    </>
  );
}

export default App;
