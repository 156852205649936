import React from 'react';
import {useEffect, useState} from 'react';
import {CampaignInsightsResponse} from '@unegma/sa2-types';
import EmptyInsight from '../../../shared-components/EmptyInsight';
import {SparklesIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';
import {AutoAwesome} from '@mui/icons-material';

type Props = {
  campaignInsights: CampaignInsightsResponse;
  campaignId: string | undefined;
};

function TopInsights({campaignInsights, campaignId}: Props) {

  // @ts-ignore
  const [topInsights, setTopInsights] = useState<any>(null!);

  useEffect(() => {
    console.log('here1')
    // @ts-ignore
    if (campaignInsights && campaignInsights.topInsights) {
      console.log('here2')
      // @ts-ignore
      console.log('top insights', JSON.parse(campaignInsights.topInsights));
      // @ts-ignore
      setTopInsights(JSON.parse(campaignInsights.topInsights));
    }
  }, [campaignInsights])

  return (
    <div className="row row3 px-9 pt-5">
      <h2>Insights</h2>
      <div className="grid-box insight-page-override">
        {!campaignInsights || !topInsights ? (
          <div className="w-full h-72">
            <EmptyInsight
              icon={<SparklesIcon className="absolute h-20 w-20" />}
              description="Insights will appear here once you have some Responses"
            />
          </div>
        ) : (
          <div className="flex flex-row pt-10 overflow-y-scroll gap-5">

            {/*{topInsights.map((insight: any, index: any) => {*/}
            {/*  return (*/}
            {/*    <div className="campaign-box insight-box">*/}
            {/*      <p className="text-base pb-6">*/}
            {/*        <AutoAwesome className="mr-2" />*/}
            {/*        Insight*/}
            {/*      </p>*/}
            {/*      <p>{insight['top-insight']}</p>*/}
            {/*      <b  style={{color:'green'}}>Suggested Action:</b>*/}
            {/*      <small style={{color:'green'}}>{insight['suggested-action']}</small>*/}
            {/*      <br/>*/}
            {/*      <b  style={{color:'red'}}>Sentiment:</b>*/}
            {/*      <small style={{color:'green'}}>{insight['sentiment']}</small>*/}
            {/*      <br/>*/}
            {/*      <b  style={{color:'yellow'}}>Keywords:</b>*/}
            {/*      <small style={{color:'green'}}>{insight['keywords']}</small>*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*})}*/}

            {/*todo this is a hacky fix for the above because on first submit, it comes as topInsights, second it comes as topInsights.results*/}
            {
              Array.isArray(topInsights) ? (
                topInsights.map((insight: any, index: any) => {
                  return (
                    <div className="campaign-box insight-box" key={index}>
                      <p className="text-base pb-6">
                        <AutoAwesome className="mr-2" />
                        Insight
                      </p>
                      <p>{insight['top-insight']}</p>
                      <br/>
                      <hr/>
                      <b style={{ color: 'white', fontSize:'16px', lineHeight:"1.5em" }}>Suggested Action:</b>
                      <p style={{ color: 'white', fontSize:'16px', lineHeight:"1.5em" }}>{insight['suggested-action']}</p>
                      <b style={{color: 'white', fontSize:'16px', lineHeight:"1.5em" }}>Sentiment:</b>
                      <p style={{color: 'white', fontSize:'16px', lineHeight:"1.5em" }}>{insight['sentiment']}</p>
                      <b style={{color: 'white', fontSize:'16px', lineHeight:"1.5em" }}>Keywords:</b>
                      <p style={{color: 'white', fontSize:'16px', lineHeight:"1.5em" }}>{insight['keywords']}</p>
                    </div>
                  );
                })
              ) : (
                topInsights?.results?.map((insight: any, index: any) => {
                  return (
                    <div className="campaign-box insight-box" key={index}>
                      <p className="text-base pb-6">
                        <AutoAwesome className="mr-2" />
                        Insight
                      </p>
                      <p>{insight['top-insight']}</p>
                      <b style={{ color: 'green' }}>Suggested Action:</b>
                      <small style={{ color: 'green' }}>{insight['suggested-action']}</small>
                      <br />
                      <b style={{ color: 'red' }}>Sentiment:</b>
                      <small style={{ color: 'green' }}>{insight['sentiment']}</small>
                      <br />
                      <b style={{ color: 'yellow' }}>Keywords:</b>
                      <small style={{ color: 'green' }}>{insight['keywords']}</small>
                    </div>
                  );
                })
              )
            }


            {/*{campaignInsights.insights.map((insight: any, index: any) => {*/}
            {/*  return (*/}
            {/*    <Link*/}
            {/*      key={index}*/}
            {/*      className="campaign-box__link pb-10"*/}
            {/*      to={`/campaigns/${campaignId}/insights/${insight.campaignQuestionHash}`}*/}
            {/*    >*/}
            {/*      <div className="campaign-box insight-box">*/}
            {/*        <p className="text-base pb-6">*/}
            {/*          <AutoAwesome className="mr-2" />*/}
            {/*          Insight*/}
            {/*        </p>*/}
            {/*        <p>{insight.insight}</p>*/}
            {/*      </div>*/}
            {/*    </Link>*/}
            {/*  );*/}
            {/*})}*/}
          </div>
        )}
      </div>
    </div>
  );
}

export default TopInsights;
