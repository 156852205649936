import {usePrivy} from '@privy-io/react-auth';
import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Button} from '@mui/material';

/**
 * Unauthenticated Navbar
 * @constructor
 */
export default function Navbar() {
  const {login, user} = usePrivy();

  const [showNavbar, setShowNavbar] = React.useState(true);
  const location = useLocation();
  console.log('pathname is: ', location.pathname);

  useEffect(() => {
    if (location.pathname.includes('/sa2-campaigns/')) {
      console.log('setting to false');
      setShowNavbar(false);
    } else {
      console.log('setting to true');
      setShowNavbar(true);
    }
  }, [location]);

  console.log(`User: ${JSON.stringify(user)}`);

  return (
    <>
      {showNavbar && (
        <div className="navbar-container">
          <div className="navbar-padding"></div>
          <div className="navbar">
            <RouterLink to={`/`} color="inherit">
              {/* prevent it not showing sometime?? */}
              {/*<img src={`${process.env.REACT_APP_BASE_URL}/sa2-logo.svg`} />*/}
              SA2
            </RouterLink>

            <Button
              // className="sa2-button"
              color="success"
              variant="contained"
              onClick={login}
            >
              Log in
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
