import EmptyInsight from '../../../shared-components/EmptyInsight';
import {SparklesIcon} from '@heroicons/react/24/outline';
import Card from '../../../shared-components/Card';
import InsightsShelf from '../../../shared-components/InsightsShelf';
import PercentToInt from '../../../helpers/PercentToInt';
import EmotionBar from '../../../shared-components/EmotionBar';
import {CampaignInsightsResponse} from '@unegma/sa2-types';
import AgeWeightingsBar from '../../../shared-components/AgeWeightingsBar';
import GenderBar from '../../../shared-components/GenderBar';

type Props = {
  campaignInsights: CampaignInsightsResponse;
};

function DataInsights({campaignInsights}: Props) {
  return (
    <div className="row4 px-9 pt-5">
      <h2>Data Insights</h2>
      <div className="w-full h-72">
        {!campaignInsights ||
        (!campaignInsights.emotionWeightings) ? (
          // !campaignInsights.ageRangeWeightings &&
          // !campaignInsights.genderWeightings) ? (
          <EmptyInsight
            icon={<SparklesIcon className="absolute h-20 w-20" />}
            description="Insights will appear here once you have some Responses"
          />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full gap-8 py-10">
            <Card
              className="w-full h-[236px] max-w-[500px]"
              title={'Key words'}
            >
              <div className="px-10 pt-5">
                <p className="pb-5">
                  {campaignInsights &&
                  campaignInsights.keywordsArray &&
                  campaignInsights.keywordsArray[0]
                    ? `${campaignInsights.keywordsArray[0]}`
                    : ''}
                </p>
                {campaignInsights &&
                campaignInsights?.keywordsArray &&
                campaignInsights.keywordsArray.length > 0 ? (
                  <InsightsShelf
                    items={[
                      `${campaignInsights.keywordsArray[1]}`,
                      `${campaignInsights.keywordsArray[2]}`,
                      `${campaignInsights.keywordsArray[3]}`,
                    ]}
                  />
                ) : null}
              </div>
            </Card>

            {/*<Card*/}
            {/*  className="w-full h-[236px] max-w-[700px]"*/}
            {/*  title={'Responses'}*/}
            {/*>*/}
            {/*  <div className="px-10 pt-5">*/}
            {/*    <p className="text-3xl pb-5">*/}
            {/*      /!*todo change to responses*!/*/}
            {/*      {campaignInsights.totalCampaignSA2s}*/}
            {/*    </p>*/}
            {/*    /!*<InsightsShelf items={[`80% Voice Notes`, `20% Text Notes`]} />*!/*/}
            {/*  </div>*/}
            {/*</Card>*/}

            <Card className="w-full h-[236px] max-w-[1400px]" title={'Emotion'}>
              <div className="px-10 pt-5">
                <p className="pb-4">
                  {
                      PercentToInt(campaignInsights.emotionWeightings.angry) >=
                      PercentToInt(campaignInsights.emotionWeightings.calm)
                    ? 'Angry'
                    : PercentToInt(campaignInsights.emotionWeightings.calm) >=
                      PercentToInt(campaignInsights.emotionWeightings.disgust)
                    ? 'Disgust'
                    : PercentToInt(campaignInsights.emotionWeightings.disgust) >=
                      PercentToInt(campaignInsights.emotionWeightings.fearful)
                    ? 'Fearful'
                    : PercentToInt(campaignInsights.emotionWeightings.fearful) >=
                    PercentToInt(campaignInsights.emotionWeightings.happy)
                    ? 'Happy'
                    : PercentToInt(campaignInsights.emotionWeightings.happy) >=
                    PercentToInt(campaignInsights.emotionWeightings.neutral)
                    ? 'Neutral'
                    : PercentToInt(campaignInsights.emotionWeightings.neutral) >=
                    PercentToInt(campaignInsights.emotionWeightings.sad)
                    ? 'Sad'
                    : 'Surprised'
                  }
                </p>
                <EmotionBar
                  emotionWeightings={campaignInsights.emotionWeightings}
                />
                <div className="flex w-full justify-center items-center">
                  <InsightsShelf
                    items={[
                      `${campaignInsights.emotionWeightings.angry} Angry`,
                      `${campaignInsights.emotionWeightings.calm} Calm`,
                      `${campaignInsights.emotionWeightings.disgust} Disgust`,
                      `${campaignInsights.emotionWeightings.fearful} Fearful`,
                      `${campaignInsights.emotionWeightings.happy} Happy`,
                      `${campaignInsights.emotionWeightings.neutral} Neutral`,
                      `${campaignInsights.emotionWeightings.sad} Sad`,
                      `${campaignInsights.emotionWeightings.surprised} Surprised`,
                    ]}
                  />
                </div>
              </div>
            </Card>

            {/*<Card*/}
            {/*  className="w-full h-[236px] max-w-[700px]"*/}
            {/*  title={'Age Range'}*/}
            {/*>*/}
            {/*  <div className="px-10 pt-5">*/}
            {/*    <div className="flex flex-row justify-items-start w-full pb-5">*/}
            {/*      <p className="text-base pr-10">*/}
            {/*        Average Age: <b>{campaignInsights.averageAge.toFixed(0)}</b>*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*    <AgeWeightingsBar*/}
            {/*      ageRangeWeightings={campaignInsights.ageRangeWeightings}*/}
            {/*    />*/}
            {/*    <div className="flex w-full justify-center items-center">*/}
            {/*      <InsightsShelf*/}
            {/*        items={[*/}
            {/*          `${campaignInsights.ageRangeWeightings['13-18']} 13-18`,*/}
            {/*          `${campaignInsights.ageRangeWeightings['19-25']} 19-25`,*/}
            {/*          `${campaignInsights.ageRangeWeightings['26-40']} 26-40`,*/}
            {/*          `${campaignInsights.ageRangeWeightings['40+']} 40+`,*/}
            {/*        ]}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Card>*/}

            {/*<Card className="w-full h-[236px] max-w-[700px]" title={'Gender'}>*/}
            {/*  <div className="px-10 pt-5">*/}
            {/*    <GenderBar*/}
            {/*      genderWeightings={campaignInsights.genderWeightings}*/}
            {/*    />*/}
            {/*    <div className="flex w-full justify-center items-center">*/}
            {/*      <InsightsShelf*/}
            {/*        items={[*/}
            {/*          `${campaignInsights.genderWeightings.male} Male`,*/}
            {/*          `${campaignInsights.genderWeightings.female} Female`,*/}
            {/*        ]}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Card>*/}
          </div>
        )}
      </div>
    </div>
  );
}

export default DataInsights;
