import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  getCampaign,
  getCampaignInsights,
  getDateFromISOString,
  getDaysBetween,
} from '@unegma/sa2-lib-frontend';
import {Bolt, Share} from '@mui/icons-material';
import {getAccessToken} from '@privy-io/react-auth';
import {
  Campaign,
  CampaignDatabaseItem,
  CampaignInsightsResponse,
} from '@unegma/sa2-types';
import LoadingScreen from '../../../shared-components/LoadingScreen';
import Summary from './Summary';
import DataInsights from './DataInsights';
import TopInsights from './TopInsights';
import ShareSA2Modal from '../../../shared-components/modals/ShareResponseModal';

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

/**
 * Campaign Screen
 *
 * @constructor
 */
export default function CampaignScreen() {
  const routeParams = useParams();
  const campaignId = routeParams.campaignId;

  const [campaign, setCampaign] = useState<Campaign>(null!);
  const [campaignInsights, setCampaignInsights] =
    useState<CampaignInsightsResponse>(null!);
  const [campaignSummary, setCampaignSummary] = useState<any>(null!);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isCampaignReady, setIsCampaignReady] = useState<boolean>(false);
  const [isCampaignInsightsReady, setIsCampaignInsightsReady] =
    useState<boolean>(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  const toggleShareModal = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  useEffect(() => {
    if (campaignId) {
      getAccessToken().then((token: any) => {
        getCampaign(ENDPOINT, token, campaignId).then(
          (response: CampaignDatabaseItem) => {
            console.log('campaignId', campaignId);
            console.log('campaign', response);
            setCampaign(response);
            setIsCampaignReady(true);
          },
        );
        getCampaignInsights(ENDPOINT, token, campaignId).then(
          (response: CampaignInsightsResponse) => {
            console.log('campaignId for insights', campaignId);
            console.log('insights', response);
            setCampaignInsights(response);
            setIsCampaignInsightsReady(true);
          },
        );
      });
    }
  }, []);

  useEffect(() => {
    if (isCampaignReady && isCampaignInsightsReady) {
      setLoading(false);
    }
  }, [isCampaignReady, isCampaignInsightsReady]);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="dashboard campaign-dashboard">
        <div className="row px-9">
          <div className="sub-row pt-10">
            <Link
              className="font-medium text-lg leading-none tracking-widest"
              to={'/'}
            >
              &#60; Back to Campaigns
            </Link>
          </div>
          <div className="sub-row pt-14">
            <h1 className="text-3xl md:text-4xl">{campaign?.question}</h1>
          </div>
          <div className="flex flex-col md:flex-row justify-between py-5">
            <div className="flex flex-row flex-wrap my-5 gap-y-2 md:justify-center md:items-center">
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs">
                  <Bolt className="campaign-box__live" /> {' Live'}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs pr-1">
                  Created{' '}
                  {campaign && campaign.Created
                    ? getDateFromISOString(campaign.Created)
                    : ''}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs pr-1">
                  {campaign && campaign.startDate && campaign.endDate
                    ? getDaysBetween(campaign.startDate, campaign.endDate)
                    : 0}{' '}
                  {'days remaining'}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs pr-1">
                  {!campaignInsights || !campaignInsights.totalCampaignSA2s
                    ? '0 SA2'
                    : campaignInsights.totalCampaignSA2s > 1
                    ? `${campaignInsights.totalCampaignSA2s} SA2s`
                    : `${campaignInsights.totalCampaignSA2s} SA2`}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <a href="/" className="text-xs pr-1">
                  {'Edit this campaign'}
                </a>
              </div>
              <div className="pr-2 mr-2 h-4 flex justify-center items-center">
                <a href="/" className="text-xs pr-1">
                  {'Export'}
                </a>
              </div>
            </div>
            <div className="column">
              <Button className="sa2-button h-8" onClick={toggleShareModal}>
                <Share className="mr-2" />{' '}
                <span className="text-xs md:text-base">
                  {'Share this SA2 campaign'}
                </span>
              </Button>
            </div>
          </div>
        </div>

        {/* row2 */}
        <Summary campaignInsights={campaignInsights} />

        {/* row3 */}
        <TopInsights
          campaignInsights={campaignInsights}
          campaignId={campaignId}
        />

        {/* row4 */}
        <DataInsights campaignInsights={campaignInsights} />

        <ShareSA2Modal
          isOpen={isOpenShareModal}
          toggleModal={toggleShareModal}
          url={location.origin + '/sa2-campaigns/' + campaignId}
        />
      </div>
    </>
  );
}
