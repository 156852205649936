import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {getAccessToken, usePrivy} from '@privy-io/react-auth';
import Button from '@mui/material/Button';
import {
  getCampaigns,
  getDateFromISOString,
  getDaysBetween,
  getUserId,
} from '@unegma/sa2-lib-frontend';
import {Bolt, EventNote} from '@mui/icons-material';
import {Campaign} from '@unegma/sa2-types';
import LoadingScreen from '../../shared-components/LoadingScreen';

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

/**
 * Home Screen
 *
 * @constructor
 */
export default function HomeScreen() {
  const {user} = usePrivy();
  const navigate = useNavigate();

  console.log('user', user);

  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const handleCreateCampaign = () => () => {
    navigate(`/create-campaign`);
  };

  useEffect(() => {
    setLoading(true);
    getAccessToken().then((token: any) => {
      getCampaigns(ENDPOINT, token, getUserId(user)).then(
        (response: Campaign[]) => {
          if (!(Object.keys(response).length === 0)) {
            console.log('campaigns', response);
            setCampaigns(response);
          }
          setLoading(false);
        },
      );
    });
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="home-dashboard">
        <div className="home-dashboard__top-row">
          <div className="home-dashboard__top-row-left">
            <h1 className="home-dashboard__title">
              Hi {/* todo improve this (see getUserId) */}
              {user && user.google && user.google.name
                ? user?.google?.name
                : ''}
            </h1>
            <p>Start connecting to your community</p>
          </div>

          <div className="home-dashboard__top-row-right">
            {campaigns && campaigns.length > 0 && (
              <>
                <Button
                  className="sa2-button"
                  variant="contained"
                  onClick={handleCreateCampaign()}
                >
                  Create a new campaign
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="pt-16">
          {campaigns.length === 0 && (
            <div className={'campaign-box-container'}>
              <div className="campaign-box">
                <div className="flex flex-col">
                  <p
                    className="font-dm-sans text-3xl font-normal
                  leading-9 tracking-tighter pb-10"
                  >
                    Create your first <br /> Campaign
                  </p>
                  <div>
                    <Button
                      className="sa2-button"
                      variant="contained"
                      onClick={handleCreateCampaign()}
                    >
                      Get started
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={'campaign-box-container'}>
            {campaigns.length > 0 &&
              campaigns.map((campaign: any, index: any) => {
                return (
                  <Link
                    key={index}
                    className="campaign-box__link"
                    to={`/campaigns/${campaign.uuid}`}
                  >
                    <div className={'campaign-box'}>
                      <p className="campaign-box__details">
                        <Bolt className="campaign-box__live" /> Live | Created{' '}
                        {campaign && campaign.Created
                          ? getDateFromISOString(campaign.Created)
                          : ''}
                      </p>
                      <div className="h-[100px] md:h-[150px] overflow-hidden">
                        <p className="campaign-box__title line-clamp-2">
                          {campaign.question}
                        </p>
                      </div>
                      <p className="campaign-box__details-2">
                        <EventNote className="campaign-box__details-2-icon" />{' '}
                        {campaign && campaign.startDate && campaign.endDate
                          ? getDaysBetween(campaign.startDate, campaign.endDate)
                          : 0}{' '}
                        days remaining
                      </p>

                      <br />
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
