import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PrivyProvider} from '@privy-io/react-auth';
import {ThemeProvider} from '@mui/material';
import MuiCustomTheme from './helpers/MuiCustomTheme';
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

// This method will be passed to the PrivyProvider as a callback
// that runs after successful login.
const handleLogin = (user: any) => {
  console.log(`User ${user.id} logged in!`);
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={MuiCustomTheme}>
      <PrivyProvider
        appId={
          process.env.REACT_APP_APP_ENV === 'production'
            ? (process.env.REACT_APP_PRIVY_APP_ID as string)
            : (process.env.REACT_APP_PRIVY_APP_ID_STAGING as string)
        }
        onSuccess={handleLogin}
      >
        <App />
      </PrivyProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
