import Modal from '@mui/material/Modal';
import Card from '../Card';
import FacebookIcon from '../../assets/icons/Facebook.icon';
import TwitterIcon from '../../assets/icons/Twitter.icon';
import LinkedInIcon from '../../assets/icons/LinkedIn.icon';
import {Close} from '@mui/icons-material';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

type Props = {isOpen: boolean; toggleModal: () => void; url: string};

function ShareSA2Modal({isOpen, toggleModal, url}: Props) {
  const copyUrl = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <div>
      <Modal open={isOpen} onClose={toggleModal}>
        <Card
          className="absolute top-1/2 left-1/2 transform-translate-50-percent -translate-x-1/2 -translate-y-1/2
        w-400 bg-[#E3E2F1] text-[#0F0030] py-5 px-10 w-[650px] h-[360px] max-w-full"
        >
          <Close
            className="absolute top-5 right-5 cursor-pointer"
            onClick={toggleModal}
          />
          <h2 className="text-2xl pb-5">Share your SA2 Campaign</h2>

          <p className="text-sm text-[#181C24] pb-2">
            Share this link with your community
          </p>

          <div
            className="cursor-pointer border-[2px] border-[#181C24] rounded-[4px] p-2"
            onClick={copyUrl}
          >
            <p className="text-sm">{url}</p>
          </div>

          <h3 className="text-lg font-[500] text-[#0F172A] pt-5">
            Or share on social
          </h3>

          <div className="flex flex-row gap-4">
            <TwitterShareButton
              title="Click to send your sa2 "
              url={`${url}`}
            >
              <TwitterIcon />
            </TwitterShareButton>
            <LinkedinShareButton title="Click to send your sa2 " url={url}>
              <LinkedInIcon />
            </LinkedinShareButton>
            <FacebookShareButton
              quote="Click to send your sa2"
              url={`${url}`}
            >
              <FacebookIcon />
            </FacebookShareButton>
            <WhatsappShareButton url={`${url}`}>
              <WhatsappIcon className="rounded-full w-[48px] h-[48px]" />
            </WhatsappShareButton>
          </div>
        </Card>
      </Modal>
    </div>
  );
}

export default ShareSA2Modal;
