import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {usePrivy} from '@privy-io/react-auth';
import {useAudioRecorder} from 'react-audio-voice-recorder';
import {
  blobToBase64,
  createResponse,
  getCampaign,
  getLogo,
  signUp,
  standardizeSEmotions,
} from '@unegma/sa2-lib-frontend';
import {
  CampaignDatabaseItem,
  SymEmotionResponse,
  SA2ApiResponse,
} from '@unegma/sa2-types';
import RecordingButton from '../shared-components/RecordingButton';
import AudioPlayer from '../shared-components/AudioPlayer/AudioPlayer';
import CircularProgress from '@mui/material/CircularProgress';

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

/**
 * SA2 Screen
 * todo this whole component needs tidying and separating into separate components
 *
 * @constructor
 */
export default function ResponsesScreen() {
  const {getAccessToken} = usePrivy();

  const routeParams = useParams();
  const campaignId = routeParams.campaignId;
  const [campaign, setCampaign] = useState<CampaignDatabaseItem>(null!); // todo replace database item types with api response types
  const [logoUri, setLogoUri] = useState<any>(null);

  // get logo
  useEffect(() => {
    getLogo(ENDPOINT, null, campaignId as string).then((response: any) => {
      if (response !== 'error') {
        setLogoUri(response);
      }
    });
  }, []);

  useEffect(() => {
    if (campaignId) {
      getCampaign(ENDPOINT, null, campaignId).then(
        (response: CampaignDatabaseItem) => {
          // todo replace database item types with api response types
          console.log('campaign', response);
          setCampaign(response);
        },
      );
    }
  }, []);

  const [recording, setRecording] = useState<any>(null);
  const [blob, setBlob] = useState<any>(null);
  const addAudioElement = (blob: any) => {
    console.log('done');
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    console.log(audio);
    setRecording(audio.src);
    setBlob(blob);
  };

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder({noiseSuppression: true, echoCancellation: true});

  useEffect(() => {
    if (!recordingBlob) return;
    console.log('recording blob', recordingBlob);
    addAudioElement(recordingBlob);
  }, [recordingBlob]);

  const [browser, setBrowser] = useState('other');

  const [transcription, setTranscription] = useState<any>(null!);
  const [sentiment, setSentiment] = useState(null!);
  const [sentimentScore, setSentimentScore] = useState(null!);
  const [emotion, setEmotion] = useState(null!);
  const [sEmotion, setSEmotion] = useState<SymEmotionResponse[] | null>(
    null,
  );
  const [sAge, setSAge] = useState(0);
  const [sGender, setSGender] = useState('');
  const [emotionScore, setEmotionScore] = useState(null!);
  const [sEmotionScore, setSEmotionScore] = useState<any>(null!);
  const [aeg, setAEG] = useState<any>(null!);
  const [audioURI, setAudioURI] = useState<any>(null!);
  const [processing, setProcessing] = useState(false);
  const [done, setDone] = useState(false);

  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  /**
   * Try setting ios chrome back to use safari (which will save it as a webm file instead of an mp4) may not work depending on whether the browser uses safari under the hood
   */
  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    // this will set the chrome browser to safari on ios (which may be needed for the audio to work because of encodings)

    if (userAgent.indexOf('Firefox') > -1) {
      console.log('firefox');
      setBrowser('firefox');
    } else if (
      userAgent.indexOf('Safari') > -1 &&
      userAgent.indexOf('Chrome') === -1
    ) {
      console.log('safari');
      setBrowser('safari');
    } else {
      console.log('other');
      setBrowser('other');
    }
  }, []);

  /**
   *
   */
  const handleExample = async () => {
    setDone(true);
    setProcessing(true);
    // Please note that this method will increase the size of your payload by approximately 33%, as base64 encoding is not space-efficient. If your audio files are large, you may run into issues with payload size limits.
    const base64Audio = await blobToBase64(blob);

    const authToken = await getAccessToken(); // this will be null for anonymous users

    // todo check SA2Response type data is the only data on the response
    const responseData: SA2ApiResponse = await createResponse(
      ENDPOINT,
      authToken,
      base64Audio,
      campaignId,
      browser,
    );
    console.log('response data:', responseData);

    setAudioURI(responseData.message);
    setTranscription(responseData.transcription);
    setSentiment(responseData.sentiment.Sentiment); // also has sentiment.SentimentScore
    setSentimentScore(responseData.sentiment.SentimentScore); // also has sentiment.SentimentScore

    if (responseData.sEmotion) {
      setSEmotion(responseData.sEmotion);
      setSEmotionScore(standardizeSEmotions(responseData.sEmotion));
    }
    if (responseData.sAge) {
      setSAge(responseData.sAge.toFixed(0));
    }
    if (responseData.sGender) {
      setSGender(responseData.sGender);
    }

    setProcessing(false);
  };

  const reRecordAudio = () => {
    setRecording(null);
    startRecording();
  };

  return (
    <>
      <div className="gallery-page sa2-feedback">
        {done && (
          <div className="sa2-feedback flex flex-col w-full h-full items-center px-5 py-20">
            {logoUri && <img src={logoUri} className="h-[100px]" />}
            {!logoUri && (
              <p className="sa2-feedback__partner-logo">Partner Logo</p>
            )}

            <div className="flex flex-col h-full justify-center items-center text-center">
              <p className="text-4xl px-5 pb-4">Thanks for your feedback!</p>

              <p className="text-lg px-5">
                We&apos;ve sent your recording to your community manager
              </p>
            </div>
          </div>
        )}

        {!done && (
          <>
            {processing && (
              <div
                className="fixed w-full h-full bg-slate-900 bg-opacity-50
              flex justify-center items-center z-50"
              >
                <CircularProgress size={100} />
              </div>
            )}

            <div className="sa2-feedback flex flex-col items-center px-5 py-20">
              {logoUri && (
                <img src={logoUri} className="h-[70px] xs:h-[100px]" />
              )}
              {!logoUri && (
                <p className="sa2-feedback__partner-logo">Your Logo</p>
              )}

              <p className="text-center text-1xl xs:text-4xl font-medium leading-normal tracking-tighter pt-5 xs:pt-10">
                {campaign && campaign.question ? campaign.question : ''}
              </p>

              <p className="text-base font-normal leading-6 tracking-tight pt-5 xs:pt-10 pb-5 xs:pb-10">
                {!isRecording && !recordingBlob && 'Tap to start recording'}
                {isRecording && 'Tap again to stop recording'}
                {!isRecording && recordingBlob && (
                  <>
                    {'Send or '}
                    <span
                      onClick={reRecordAudio}
                      className="text-sa2-links cursor-pointer"
                    >
                      re-record message
                    </span>
                  </>
                )}
              </p>

              {browser === 'safari' && (
                <RecordingButton
                  isRecording={isRecording}
                  recordingTime={recordingTime}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  recordingBlob={recordingBlob}
                  addAudioElement={addAudioElement}
                  handleExample={handleExample}
                />
              )}
              {browser === 'other' && (
                <RecordingButton
                  isRecording={isRecording}
                  recordingTime={recordingTime}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  recordingBlob={recordingBlob}
                  addAudioElement={addAudioElement}
                  handleExample={handleExample}
                />
              )}
              {browser === 'firefox' && (
                <RecordingButton
                  isRecording={isRecording}
                  recordingTime={recordingTime}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  recordingBlob={recordingBlob}
                  addAudioElement={addAudioElement}
                  handleExample={handleExample}
                />
              )}

              {/*{!recordingBlob && !isRecording && (*/}
              {/*  <p*/}
              {/*    className="text-blue-500 text-center font-dm-sans pt-10*/}
              {/*  text-base font-semibold leading-snug tracking-tight"*/}
              {/*  >*/}
              {/*    Type a note instead*/}
              {/*  </p>*/}
              {/*)}*/}

              {!isRecording && recordingBlob && (
                <div className="pt-5 xs:pt-9">
                  <p className="text-center text-base font-normal leading-snug tracking-tight pb-4">
                    Listen to your recording
                  </p>

                  <AudioPlayer recording={recording} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className="fixed bottom-0 left-0 right-0 p-4 text-white text-center
              flex flex-col justify-center items-center"
      >
        <div>
          <img src={`${process.env.REACT_APP_BASE_URL}/sa2-logo.svg`} />
        </div>
        <p className="text-gray-600 text-center font-dm-sans text-base font-normal leading-normal">
          By continuing, you agree to our
          <br />
          <span className="text-sa2-white">Terms of Service</span> and{' '}
          <span className="text-sa2-white">Privacy Policy</span>
        </p>
      </div>
    </>
  );
}
