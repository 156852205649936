import React from 'react';
import {CampaignInsightsResponse} from '@unegma/sa2-types';
import EmptyInsight from '../../../shared-components/EmptyInsight';
import {BookOpenIcon} from '@heroicons/react/24/outline';
import SentimentBar from '../../../shared-components/SentimentBar';
import PercentToInt from '../../../helpers/PercentToInt';

type Props = {
  campaignInsights: CampaignInsightsResponse;
};

function Summary({campaignInsights}: Props) {
  return (
    <div className="row row2">
      <div className="column column75 px-9 py-5">
        <h2>Summary</h2>

        {!campaignInsights || !campaignInsights.summary ? (
          <EmptyInsight
            icon={<BookOpenIcon className="absolute h-20 w-20" />}
            description="Summary of findings will appear here once you have some Responses"
          />
        ) : (
          <p className="pt-5 max-w-7xl text-base md:text-2xl">
            {campaignInsights.summary}
          </p>
        )}
      </div>

      <div className="column column25 border-t-2 md:border-l-2 border-[#383C44] pl-9">
        <p className="pt-5 text-sm">Overall sentiment</p>

        {!campaignInsights && (
          <div className="h-5/6 flex flex-col pt-10">
            <p className="greyed-out pb-6">No sentiment available</p>
            <SentimentBar emptyState={true} />
          </div>
        )}

        {campaignInsights && campaignInsights.campaignSentiment && (
          <div className="pt-5 pb-5 pr-9 md:pr-10">
            <p className="pb-4">
              {PercentToInt(campaignInsights.campaignSentiment.positive) >
              PercentToInt(campaignInsights.campaignSentiment.negative)
                ? 'Positive'
                : PercentToInt(campaignInsights.campaignSentiment.negative) >
                  PercentToInt(campaignInsights.campaignSentiment.neutral)
                ? 'Negative'
                : 'Neutral'}
            </p>
            <SentimentBar
              emptyState={false}
              positive={campaignInsights.campaignSentiment.positive}
              negative={campaignInsights.campaignSentiment.negative}
              neutral={campaignInsights.campaignSentiment.neutral}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Summary;
