import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import * as React from 'react';
import {getCampaign, getCampaignInsights} from '@unegma/sa2-lib-frontend';
import {getAccessToken} from '@privy-io/react-auth';
import {Campaign, CampaignInsightsResponse, Insight} from '@unegma/sa2-types';

const ENDPOINT = `${
  process.env.REACT_APP_APP_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT_STAGING
}`;

/**
 * Gallery Screen
 *
 * @constructor
 */
export default function InsightScreen() {
  const routeParams = useParams();
  const campaignId = routeParams.campaignId;
  const insightId = routeParams.insightId;

  const [campaign, setCampaign] = useState<Campaign>(null!);
  const [campaignInsights, setCampaignInsights] =
    useState<CampaignInsightsResponse>(null!);
  const [insight, setInsight] = useState<Insight>(null!);

  useEffect(() => {
    if (campaignId) {
      getAccessToken().then((token: any) => {
        getCampaign(ENDPOINT, token, campaignId).then((response: Campaign) => {
          console.log('campaignId', campaignId);
          console.log('campaign', response);
          setCampaign(response);
        });
        getCampaignInsights(ENDPOINT, token, campaignId).then(
          (response: CampaignInsightsResponse) => {
            console.log('campaignId for insights', campaignId);
            console.log('insights', response);
            setCampaignInsights(response);

            // todo this might need to change
            campaignInsights.insights.filter((insight: Insight) => {
              if (insight.campaignQuestionHash === insightId) {
                setInsight(insight);
              }
            });
          },
        );
      });
    }
  }, []);

  return (
    <>
      <br />
      <br />
      <br />
      <div className="dashboard campaign-dashboard">
        <p>{insight && insight.insight}</p>
      </div>
    </>
  );
}
